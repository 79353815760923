@import "./include-media.scss";

.menu-product-item {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 29px;
  text-decoration: none;
}

.menu-product-item.header {
  margin-top: 20px;
  font-weight: bold;
}
.menu-product-item.sub-item {
  margin-top: 10px;
}

.menu-product-item.sub-item.link {
  margin-top: 5px;
}

.MuiBox-root.menu-products-container {
  margin-top: 140px;
  margin-left: 70px;
  display: flex;
  flex-direction: column;
}

.MuiBox-root.menu-products-container-2 {
  margin-top: 65px;

  .product-image-bg {
    height: 500px;
    width: 400px;
    float: right;

    .product-image {
      position: absolute;
      right: 73px;
      top: 230px;
    }
  }

}

.menu-products-title {
  float: left;
  font-family: Oswald, serif;
  font-size: 45px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.menu-products-body {
  height:590px;
  overflow-y:auto;
  margin-right:50px;

  .menu-products-description {
    font-family: Roboto, serif;
    font-size: 24px;
    line-height: 29px;
  }
  .menu-products-list {
    //overflow-y:auto;
    //height: 420px;
  }
  .leave-a-message {
    margin-top: 50px;
    font-family: Roboto, serif;
    font-size: 20px;
    line-height: 29px;
    padding-right: 100px;
  }
}

@include media('<=phone') {
  .menu-product-item {
    font-size: 16px;
    line-height: 29px;
  }

  .menu-product-item.header {
    margin-top: 10px;
    font-weight: bold;
  }
  .menu-product-item.sub-item {
    margin-top: 5px;
  }

  .menu-product-item.sub-item.link {
    margin-top: 5px;
  }

  .MuiBox-root.menu-products-container {
    margin: 20px 40px;
  }

  .MuiBox-root.menu-products-container-2 {
    display:none;
    margin-top: 65px;

    .product-image-bg {
      height: 500px;
      width: 400px;
      float: right;

      .product-image {
        position: absolute;
        right: 73px;
        top: 230px;
      }
    }

  }


  .menu-products-title {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 20px;
    line-height: 40px;
    align-self: center;
  }
  .menu-products-body {
    height:100%;
    margin: unset;

    .menu-products-description {
      font-size: 14px;
      line-height: 20px;
    }
    .leave-a-message {
      margin-top: 50px;
      font-family: Roboto, serif;
      font-size: 13px;
      line-height: 20px;
      padding-right: unset;
    }
  }
}