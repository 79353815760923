@import "./include-media.scss";

@include media('<=phone') {
  .nav-drawer-container {
    .MuiDrawer-paper {
      background-color: #293127;
      color: white;
      overflow-y: visible;
    }

    .nav-drawer-icon {
      width: 25px;
      height: 25px;
      padding: 10px;
      cursor: pointer;
    }

    .nav-bar-input {
      background-color: #293127;
      color: #FFFFFF;
      display: flex;
      height: 48px;

      input.MuiInputBase-input {
        font-size: 14px;
      }

      .search-icon {
        color: #FFFFFF;
        padding-left: 18px;
        margin-right: 10px;
      }
    }
  }

  .options-list-box {
    padding-inline-start: 0;
    width: 300px;
    position: absolute;
    margin: 0;
    left: 50px;
    z-index: 1;
    list-style: none;
    background-color: #FAFAFA;
    color: #000000DE;
    max-height: 170px;
    overflow: auto;

    li[data-focus="true"] {
      background-color: #E1E1E1;
      color: #000000DE;
      cursor: pointer;
    }

    li:active {
      background-color: #E1E1E1;
      color: #000000DE;
   }

    .option-item {
      padding: 7px 0 7px 17px;

      .item-text {
        font-size: 12px;
        line-height: 20px;
        color: #000000DE;
      }

      .sub-item-text {
        color: #AEAEAEDE;
        font-size: 10px;
        line-height: 20px;
      }
      .wrapped {
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

  }

  .MuiList-root.nav-menu-list {
    width: 200px;
    padding-top: 0;
    padding-bottom: 0;

    .nav-menu-item {
      height: 50px;
      padding: 17px 0 17px 40px;

      .menu-item-text {
        font-size: 14px
      }
    }
  }
}