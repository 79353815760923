@import "include-media";

#faq-page {
  margin-top: 110px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-container {
    width: 1400px;

    .header{
      font: normal normal bold 45px/61px Oswald;
      margin-bottom: 20px;
    }
  }

  .faq-info-container {
    position: relative;
    z-index: 2;
    box-shadow: 20px 20px 20px #00000029;
    width: 1400px;
    height: 600px;
    background-color: #FFFFFF;
    padding: 26px 36px;

    .faq-item-list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding-right: 50px;
      box-sizing: border-box;

      .category-list-item {
        background-color: #F1F1F1;
        margin: 5px 0;
        padding-left: 10px;
        width: 100%;

        .category-list-item-text > span {
          font: normal normal bold 24px/32px Oswald;
        }
      }
      .category-child-list-item {
        flex-direction: column;
        align-items: normal;
        padding-left: 25px;

        div.question {
          font: normal normal bold 22px/30px Oswald;
          margin-bottom: 10px;
        }

        div.answer {
          font: normal normal normal 20px/24px Roboto;
          color:  #707070;
          margin-bottom: 25px;
        }
      }
    }
  }

  img.faq-info-bg {
    position: absolute;
    width:100%;
    margin-top: 160px;
    content: url("/images/blur-close-up-dew-droplets.png")
  }
}

@include media("<=phone") {
  #faq-page {
    margin: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-container {
      margin-top: 20px;
      width: unset;

      .breadcrumb-container {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .header{
        font: normal normal bold 30px/41px Oswald;
        margin-bottom: 10px;
      }
    }

    .faq-info-container {
      position: relative;
      margin: 0 0 40px 0;
      z-index: 2;
      box-shadow: 20px 20px 20px #00000029;
      width: 385px;
      height: 570px;
      background-color: #FFFFFF;
      padding: 26px 16px;
      box-sizing: border-box;

      .faq-item-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-right: 10px;
        box-sizing: border-box;

        .category-list-item {
          background-color: #F1F1F1;
          margin: 5px 0;
          padding-left: 10px;
          width: 100%;

          .category-list-item-text > span {
            font: normal normal bold 14px/19px Oswald;
          }
        }
        .category-child-list-item {
          flex-direction: column;
          align-items: normal;
          padding-left: 12px;

          div.question {
            font: normal normal bold 14px/19px Oswald;
            margin-bottom: 10px;
          }

          div.answer {
            font: normal normal normal 12px/14px Roboto;
            color:  #707070;
            margin-bottom: 25px;
          }
        }
      }
    }

    img.faq-info-bg {
      width:100%;
      margin-top: 120px;
      margin-bottom: 65px;
      content: url("/images/blur-close-up-dew-droplets-mobile.png")
    }
  }
}