@import "./include-media.scss";

.transparent{
  background-color: transparent !important;
}

.navbar {
  display: flex;
  background-color: #333333;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 65px;
  transition: background-color 0.2s ease-in-out;

  img.navbar-logo {
    display: flex;
    padding: 7px 20px;
    width: 100px;
    height: 50px;
  }

  .navbar-menu{
    margin-left: auto;
    margin-right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;

    .navbar-list-item {
      float: left;
      height: 100%;

      >a {
        height: 100%;
        padding: 0 25px;
        color: white;
        display: flex;
        text-decoration: none;
        text-align: center;
        align-items: center;

        &:hover {
          background-color: #111111;
          transition: background-color 0.2s ease-in-out;
        }
      }
    }
  }

}

.search-bar-input {
  .input-base {
    display: flex;
    height: 48px;
    color: #FFFFFF;

    .search-icon {
      color: #FFFFFF;
      padding-left: 18px;
      margin-right: 28px;
      height: 17px;
      width: 17px;
    }

    input {
      font-size: 17px;
    }
  }

  .listbox {
    padding-inline-start: 0;
    width: 300px;
    position: absolute;
    margin: 0;
    right: 0;
    z-index: 1;
    list-style: none;
    background-color: #FAFAFA;
    color: #000000DE;
    max-height: 170px;
    overflow: auto;

    & li[data-focus="true"] {
      background-color: #E1E1E1;
      color: #000000DE;
      cursor: pointer;
    }

    & li:active {
      background-color: #E1E1E1;
      color: #000000DE;
    }
  }
}

.rc-menu {
  .product-item {
    background-color: #FFFFFF;
    height: 40px;
  }

  .product-item-hover {
    height: 40px;
    background-color: #F2F2F2;
    color: #3E6617;
  }

  .rc-menu__item--disabled {
    color: #000000;
    cursor: default;
  }

  .rc-menu__item--anchor {
    display: flex !important;
    text-decoration: none;
    color: #000000;
  }
}

p.MuiTypography-root.breadcrumbs, nav.MuiTypography-root.breadcrumbs {
  color: #3E3E3E;
  opacity: 50%;
  font-size: calc(16px + 0.10vw);
  line-height: 19px;
  font-family: RobotoBold, sans-serif;
  font-weight: Bold;
  letter-spacing: 0
}

@include media('<=desktop') {
  .navbar {
    height: 50px;

    img.navbar-logo {
      padding: 0 15px;
      width: 60px;
    }

    .navbar-list .navbar-list-item > a {
      font-size: 11px;
      height: 100%;
      padding: 0 18px;
    }
  }

  .search-bar-input .input-base input {
    font-size: 13px;
  }

  .rc-menu {
    .product-item {
      height: 30px;
      font-size: 0.75rem;
    }

    .product-item-hover {
      height: 30px;
      font-size: 0.75rem;
    }
  }
}

@include media('<=phone') {
  p.MuiTypography-root.breadcrumbs, nav.MuiTypography-root.breadcrumbs {
    font-size: calc(12px + 0.10vw);
    line-height: 19px;
    letter-spacing: 0
  }
}
