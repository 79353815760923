@import "./include-media.scss";

#career-page {
  .breadcrumb-container {
    margin-top: 130px;
    margin-left: 270px;
    display: flex;
  }

  .careers-container {
    padding-left: 270px;
    padding-right: 50px;

    .title {
      font: normal normal bold 45px/61px Oswald;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .section-content {
      font: normal normal normal 20px/24px Roboto;
    }

    .section-header {
      font: normal normal bold 24px/32px Oswald;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .jobs-container {
      height: 300px;
      width: 300px;
      overflow-y: auto;
      margin-top: 20px;

      .job-title-text {
        font: normal normal normal 18px/22px Roboto;
        margin-bottom: 20px;
      }
    }
  }

  #career-display-container {
    position: relative;

    .career-display {
      position: absolute;
      width: 700px;
      height: 720px;
      top: 120px;
      left: 140px;

      background-repeat: no-repeat;
      background-image: url('/images/photo-of-people-doing-handshakes.png');
      background-size: 100%;
    }

    .career-display-no-img {
      position: absolute;
      width: 700px;
      height: 720px;
      top: 120px;
      left: 140px;
      box-shadow: 20px 20px 20px #00000029;

      .job-title {
        font: normal normal bold 30px/41px Oswald;
        text-decoration: underline;
        margin: 20px 0 20px 20px;
      }

      .job-description {
        margin: 10px 40px;
        font: normal normal normal 18px/22px Roboto;
        height: 600px;
        overflow-wrap: break-word;
        overflow-y: auto;
      }
    }

    .careers-bg {
      margin-top: 229px;
      content: url("/images/shallow-focus-of-green-leaves.png");
      width: 100%;
      height: 620px;
    }
  }
}

@include media("<=phone"){
  #career-page {
    .breadcrumb-container {
      margin: 20px 0 0 0;
      display: flex;
      justify-content: center;
    }

    .careers-container {
      padding: 0 37px;

      .title {
        font: normal normal bold 30px/41px Oswald;
        margin: 10px 0;
        justify-content: center;
        display: flex;
      }

      .section-content {
        font: normal normal normal 16px/19px Roboto;
      }

      .section-header {
        font: normal normal bold 14px/19px Oswald;
        margin-top: 10px;
        margin-bottom: 25px;
      }

      .jobs-container {
        height: 250px;
        width: 300px;
        overflow-y: auto;
        margin-top: 20px;

        .job-title-text {
          font: normal normal normal 14px/17px Roboto;
          margin-bottom: 20px;
          padding-left: 10px;
        }
      }
    }

    #career-display-container {
      position: relative;


      .career-display {
        width: 370px;
        height: 700px;

        top: unset;
        left: unset;

        background-repeat: no-repeat;
        background-image: url('/images/photo-of-people-doing-handshakes-mobile.png');
        background-size: 100%;

        display: flex;
        margin: 0 auto -710px auto;
        position: relative;
      }

      .career-display-no-img {
        position: relative;
        width: 370px;
        height: 700px;
        top: unset;
        left: unset;
        box-shadow: 20px 20px 20px #00000029;

        display: flex;
        margin: 0 auto -670px auto;
        flex-direction: column;

        .job-title {
          font: normal normal bold 18px/25px Oswald;
          margin: 20px 0 20px 20px;
        }

        .job-description {
          margin: 10px 40px;
          font: normal normal normal 16px/19px Roboto;
          height: 570px;
          overflow-wrap: break-word;
          overflow-y: auto;
        }
      }

      .careers-bg {
        margin: 30px 0;
        content: url("/images/shallow-focus-of-green-leaves-mobile.png");
        width: 100%;
        height: 600px;
      }

      .section-header {
        padding: 0 38px;
        font: normal normal bold 14px/19px Oswald;
        margin-top: 50px;
        margin-bottom: 25px;
      }

      .section-content {
        font: normal normal normal 16px/19px Roboto;
        padding: 0 38px;
        margin-bottom: 50px;
      }
    }
  }
}