@import "./include-media";

#not-found-page {
  display: flex;
  height: 100%;
  align-content: center;

  div.nf-image-container {
    .flex-container {
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: flex-end;

      .leaf-bg-img {
        position: relative;
        background-image: url("/images/green-leaf-plant-missing.png");
        width: 400px;
        height: 500px;
        margin-top: 80px;
        margin-right: 90px;

        .missing-puzzle-img {
          position: relative;
          background-image: url("/images/missing-puzzle.png");
          width: 370px;
          height: 450px;
          margin-left: 120px;
          margin-top: -75px;

          .stikwel-logo-img {
            position: absolute;
            background-image: url("/images/stikwel-silver-with-emboss.png");
            background-repeat: no-repeat;
            width: 200px;
            height: 100px;
            bottom: 0;
            margin: 40px 50px;
          }
        }
      }
    }
  }

  div.nf-text-container {
    padding: 50px;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;

    .nf-text {
      width: 470px;
      height: 270px;
      font: normal normal bold 50px/68px Oswald;
    }
    .nf-button {
      background-color: #293127;
      color: #FFFFFF;
      font-size: 28px;
      width: 270px;
      height: 80px;
      margin-top: 50px;
    }
  }
}

@include media("<=phone") {
  #not-found-page {
    div.nf-image-container {
      .flex-container {
        height: unset;
        justify-content: center;

        .leaf-bg-img {
          width: 220px;
          height: 275px;
          margin-top: 110px;
          margin-right: 50px;
          background-size: contain;

          .missing-puzzle-img {
            width: 203px;
            height: 247px;
            margin-left: 60px;
            margin-top: -30px;
            background-size: contain;

            .stikwel-logo-img {
              width: 110px;
              height: 56px;
              margin: 30px 20px;
              background-size: contain;
            }
          }
        }
      }
    }

    div.nf-text-container {
      height:unset;
      padding: 20px 70px;
      display: flex;
      flex-direction: column;
      justify-content: unset;

      .nf-text {
        height: unset;
        width:unset;
        font: normal normal bold 26px/35px Oswald;
      }
      .nf-button {
        font-size: 15px;
        width: 150px;
        height: 45px;
        margin-top: 25px;
        align-self: center;
      }
    }
  }
}