textarea.MuiInputBase-input {
  line-height: 24px;
  font-size: 20px;
}

input.MuiInputBase-input {
  font-size: 20px;
}

.modal input.MuiInputBase-input,
.modal textarea.MuiInputBase-input,
.maintenance input.MuiInputBase-input,
.maintenance textarea.MuiInputBase-input{
  font-size: 16px;
  line-height: 24px;
}

label.MuiFormLabel-root, label.Mui-focused {
  font-size: 14px;
}

.MuiFormHelperText-root {
  text-align: right !important;
  font-size: 12px !important;
  padding-top: 3px;
}

.MuiInputLabel-formControl {
  transform: translate(0, 1vw) scale(1) !important;
}

.MuiInputLabel-shrink {
  transform: translate(0, 0.07vw) scale(0.75) !important;
}

label + .MuiInput-formControl {
  margin-top: 10px !important;
}

.MuiInputBase-multiline {
  padding: 6px 0 6px !important;
}

input#sidebar-search.MuiInputBase-input {
  font-size: 14px;
}

.MuiList-padding

@media screen and (max-width: 425px) {
  textarea.MuiInputBase-input {
    line-height: 24px;
    font-size: 16px;
  }

  input.MuiInputBase-input {
    font-size: 16px;
  }

  label.MuiFormLabel-root, label.Mui-focused {
    font-size: 12px;
  }

  .MuiFormHelperText-root {
    font-size: 9px !important;
  }

}


.product-maint-tbl, .career-maint-tbl {
  border-top: 1px solid #707070;
}

.product-maint-tbl tr > td,
.career-maint-tbl tr > td {
  border-top: 1px solid #707070
}

.product-maint-tbl tr:nth-child(odd) > td,
.career-maint-tbl tr:nth-child(odd) > td {
  background-color: #F2F2F2;
}

.news-carousel {
  width: 50%;
}

.news-carousel .news-item {
  width: 100%;
  height: 300px;
}

button.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
}

button.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}

ul.react-multi-carousel-dot-list {
  bottom: 15px;
}