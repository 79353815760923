@import "./include-media.scss";

.title-section {
  width: 550px;
  margin-top: 190px;
  margin-left: 55px;
  position: absolute;
  color: #FFFFFF;
}

.icon {
  color: #403A2F;
  background-color: #F2F1E9;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: auto 9px;
  cursor: pointer
}

.h1 {
  font-family: Oswald, sans-serif;
  font-size: calc(16px + 4vw);
  line-height: 126px;
  width: 520px;
}

.h2 {
  margin-top: 20px;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  line-height: 37px;
}

.stikwel-icon {
  width: 200px;
  height: 100px;
  filter: drop-shadow(2px 2px 2px #222);
}

.product-section {
  margin: 65px 0;
  width: 100%;
  height: 460px;
  background: url("/images/close-up-photography-of-leaves-with-droplets.png") no-repeat center;
  background-size: cover;

  .products-section-title {
    display: none;
  }

  .products-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 50px;
  }

  .products-paper-bg {
    z-index: 2;
    width: 95%;
    height: 500px;
    display: flex;
    margin: -75px auto 0 auto;
  }

  div.products-bg {
    margin: 0px -55px;
    align-self: center;
    background-color: #293127;
    height: 400px;
    width: 95%;
    position: absolute;
    padding: 10px 160px;
    box-sizing: border-box;
  }

  div.product-item.MuiPaper-root.product-item {
    margin: 10px;
    padding: 30px 0;
    height: 360px;
    display: flex;
    flex-direction:column;
    align-items:center;
    box-sizing:border-box;
  }
}

.last-section {
  background: url("/images/blur-branches-close-up-environment.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 515px;

  .section-contents {
    display: flex;
    justify-content: center;
    padding: 25px 0;

    span.section-title {
      background-color: #3E6617;
      color: #FFFFFF;
      font-family: Roboto, sans-serif;
      letter-spacing: 6px;
      font-size: 20px;
      padding: 8px 75px;
      display: inline-block;
    }

    .section-body {
      width: 600px;
      height: 320px;
      letter-spacing: 4px;
    }

  }

  .news {
    background-color: #3E7720;
    z-index: 2;
    position: relative;

    .news-item {
      background-size: cover;
      background-position: center;
      margin: 10px;
      height: 300px;
      cursor: pointer;

      .news-item-text {
        color: #FFFFFF;
        white-space: break-spaces;
        font-family: Roboto, sans-serif;
        letter-spacing: 4px;
        height: 100%;
        display: flex;
        padding: 0 40px 20px 40px;
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: border-box;
        overflow-wrap: break-word;

        background-color: rgba(0,0,0,0.3);
        text-align: left;
      }
      div.news-item-text:hover {
        background-color: rgba(0,0,0,0.5);
        transition: background-color 0.2s ease-in-out;
      }
    }
  }

  .about-us {
    padding: 20px 30px;
    box-sizing:border-box;
    background-color: #0E281D;
    color: #FFFFFF;
  }

  .careers {
    padding: 20px 40px;
    box-sizing:border-box;
    background-image: url("/images/photo-of-people-doing-handshakes-2.png");
    background-repeat: no-repeat;
    background-size: 100%;
    font-size:16px;
    text-shadow: 1px 1px 6px #00000029;
    color: #000000;
  }

  .contact-link {
    display: block;
    font-size: 46px;
    letter-spacing: 0.92px;
    text-align: center;
    color: #FFFFFF
  }

  .contact-link:hover {
    color: #C4E661
  }
}

.footer-section {
  width: 100%;
  height: 140px;
  background-color: #293127;
  display: flex;
  justify-content: space-between;
}

.copyright {
  margin: auto 60px;
  color: #F2F1E9;
  font-size: 10px;
  line-height: 20px;
  white-space: break-spaces;
}

.links-and-location {
  display: flex;
  margin: auto 60px;

  .icon-container {
    margin: auto
  }

  .location {
    margin-left: 10px;
    color: #F2F1E9;
    font-size: 10px;
    line-height: 12px;
    white-space: break-spaces;
  }
}

@include media('<=desktop') {
  .title-section {
    width: 550px;
    margin-top: 125px;
    margin-left: 55px;
  }

  .stikwel-icon {
    width: 150px;
    height: 75px;
  }

  .h1 {
    font-family: Oswald, sans-serif;
    line-height: 100px;
    width: 430px;
  }

  .h2 {
    font-size: calc(12px + 1vw);
    line-height: 30px;
  }

  .product-section {
    div.products-bg {
      padding: 10px 80px;
    }
  }

  .last-section {
    height: 415px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .section-contents {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span.section-title {
        letter-spacing: 6px;
        font-size: 16px;
        padding: 8px 75px;
      }

      .section-body {
        width: 400px;
        height: 200px;
        letter-spacing: 2.5px;
        font-size: 10px;
      }
    }

    .news {
      .news-item {
        margin: 10px;
        height: 180px;
      }
    }

    .contact-link {
      font-size: 30px;
    }
  }
}

@include media('<=phone') {
  .nav-bar-mobile {
    background-color: #293127;
    font-size: calc(12px + 1vw);
    color: #FFFFFF;
    height: 50px;
    display: flex;
    align-items: center;

    .menu-icon-mobile {
      margin-left: 5px;
      width: 30px !important;
      height: 30px !important;
      color: #FFFFFF;
      z-index: 2;
      cursor: pointer;

    }
  }

  .title-section {
    width: 100%;
    margin: 260px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stikwel-icon-mobile {
    display:flex;
    margin-right: 10px;
    margin-left: auto;
    height: 30px
  }

  .stikwel-icon {
    display: none;
  }

  .h1 {
    font-size: calc(16px + 7vw);
    text-align: center;
    line-height:68px;
    color: black;
    width: 65%;
  }

  .h2 {
    color: black;
    font-size: calc(12px + 1vw);
    letter-spacing: 0.16px;
    line-height:19px;
    text-align: center;
    width: 300px;
  }

  .product-section {
    margin: 350px 0 90px 0;
    height: 623px;
    background: url("/images/close-up-photography-of-leaves-with-droplets-mobile.png") no-repeat center;

    .products-section-title {
      display: flex;
      color: white;
      font-family: Roboto, sans-serif;
      font-size: calc(16px + 5vw);
      justify-content: center;
      font-weight: bold;
      letter-spacing: 2px;
      padding: 0 100px;
      text-align: center;
    }

    .products-container {
      padding: unset;
    }

    .products-paper-bg {
      background-color: transparent;
      box-shadow: none;
      margin: -25px auto 0 auto;
    }

    div.products-bg{
      margin: 0 auto;
      padding: 0 30px;
    }

    div.product-item.MuiPaper-root.product-item {
      width:300px;
      margin: 20px auto;
    }

  }

  .last-section {
    background: url("/images/blur-branches-close-up-environment-mobile.png") no-repeat center;
    height: 100%;

    .section-contents {
      align-items: center;
      flex-direction: column;
      text-align: -webkit-center;

      span.section-title {
        margin: 30px 93px 0;
        padding: 8px 75px;
        text-align: center;
      }

    }

    .news {
      .news-item {
        div.news-item-text {
          background-color: rgba(0,0,0,0.25);
          text-align: left;
        }
      }
    }

    .contact-link {
      margin: 10px 50px 30px 50px;
    }
  }

  .footer-section {
    padding: 20px 60px;
    flex-direction: column-reverse;
    font-size: 7px;
    line-height: 9px;
    height: auto;

    svg.icon {
      width: 50px;
      height:50px;
    }

    .copyright {
      margin: 5px auto 0;
      white-space: break-spaces;
      text-align: center;
      line-height: 13px;
    }

    .links-and-location {
      display: flex;
      flex-direction: column;
      margin: auto auto 15px;
      text-align: center;

      .icon-container {
        margin: auto;
      }

      .location {
        margin-top: 10px;
      }
    }
  }

}
