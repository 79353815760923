/*fonts here*/
@font-face {
  font-family: 'impact';
  src: url('/fonts/impact.ttf') format('truetype'),
  url('/fonts/impact-webfont.woff') format('woff'),
  url('/fonts/impact-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Oswald';
  src: url('/fonts/Oswald-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: url('/fonts/Roboto-Light.ttf') format('truetype');
}

/*global classes*/
.root{
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sub{
  padding: 0 11.8vw;
  text-align: right;
}

.sub {
  font-size: 1.4vw;
}

.bold {
    font-family: RobotoBold, sans-serif;
    font-size: 1vw;
}

.wrap-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ql-container {
  border: none !important;
}

.ql-toolbar.ql-snow{
  border:none !important;
  border-bottom: 1px solid #ccc !important;
}

/*Scrollbar styles (Not available for firefox IE/Edge)*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 2px;
}

::-webkit-scrollbar-track:horizontal {
  height: 2px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Product Page*/

a.dl-content {
  color: #707070;
  font-size: 0.9375vw;
  padding-top: 0.3vw;
}

/*Contact Us Page*/

.contact-icon {
    width: 0.93vw;
    height: 0.93vw;
}

.product-menu-modal {
  display: flex;
  background-color: gray;
  z-index: 3;
}

.product-menu-section {
  background-color: white;
  margin: 5px;
}

.product-menu-section-item {
  padding: 10px;
  width: 250px;
}

.product-menu-section-item:hover {
  background-color: #F2F2F2;
}

.product-carousel-modal {
  display: flex;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  z-index: 3;
  background-color: #C4E661;
  overflow: auto;
}

div.MuiPaper-root .product-item :hover {
  background-color: #C4E661;
}
