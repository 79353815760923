@import "./include-media.scss";

#contact-us {
  label.MuiFormLabel-root, label.Mui-focused {
    font-size: 0.729vw;
  }

  input.MuiInputBase-input, textarea.MuiInputBase-input {
    font-size: 1.042vw;
    padding: 0.313vw 0 0.365vw 0;
  }

  textarea.MuiInputBase-input {
    height: 3.906vw
  }

  .MuiFormHelperText-root {
    font-size: 0.625vw
  }

  .breadcrumb-container {
    margin-top: 141px;
    margin-left: 387px;
    display: flex;
  }

  .title-mobile {
    display:none;
  }

  div.form-section {
    position: absolute;
    z-index: 2;
    box-shadow: 1.042vw 1.042vw 1.042vw #00000029;
    width: 29.948vw;
    height: 35.156vw;
    top: 9.896vw;
    left: 20.156vw;
    background-color: #FFFFFF;

    .content {
      margin: 3.646vw 5.208vw;

      .title {
        font-family: Oswald, sans-serif;
        font-size: 2.083vw;
        line-height: 2.865vw;
        margin-bottom: 1.042vw;
      }

      .send-button {
        float: right;
        width: 4.688vw;
        height: 1.875vw;
        background-color: #3E6617;
        color: #FFFFFF;
        margin-top: 1.563vw;
        font-size: 0.729vw;
      }
    }
  }

  div.bg-leaves {
    margin-top: 4.167vw;
    width: 100%;
    height: 30.729vw;
    background-image: url("/images/top-view-of-fern-leaves.png");
  }

  .contact-details-root {
    align-self: center;

    .contact-details-container {
      align-self: center;
      margin-top: 10.417vw;
      padding-left: 3.646vw;
      display: flex;
      flex-direction: column;

      .title {
        font-family: Oswald, sans-serif;
        font-size: 1.563vw;
        margin-bottom: 1.042vw;
      }

      .detail {
        padding-top: 1.042vw;

        .bold-text {
          font-family: RobotoBold, sans-serif;
          font-size: 1.042vw;
        }

        .sub-text {
          color: #707070;
          display: inline-flex;
          font-size: 1.042vw;
          line-height: 1.250vw;
        }
      }
    }
  }

  .footer-container {
    position: fixed;
  }
}

@include media('<=phone') {
  label.MuiFormLabel-root, label.Mui-focused {
    font-size: 14px !important;
  }

  input.MuiInputBase-input, textarea.MuiInputBase-input {
    font-size: 20px !important;
  }

  textarea.MuiInputBase-input {
    height: 75px
  }

  .MuiFormHelperText-root {
    font-size: 12px !important
  }

  #contact-us {
    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .breadcrumb-container {
      margin: 20px 0 10px 0;
      justify-content: center;
    }

    .title-mobile {
      display: flex;
      justify-content: center;
      font: normal normal bold 30px/41px Oswald;
      margin-bottom: 20px;
    }

    div.form-section {
      z-index: 2;
      width: 375px;
      height: unset;
      background-color: #FFFFFF;
      margin-bottom: -580px;

      position: unset;
      box-shadow: 10px 10px 10px #00000029;
      top: unset;
      left: unset;

      .content {
        margin: unset;
        justify-content: center;
        padding: 50px 28px 20px;

        .title {
          display: none;
        }

        .send-button {
          display: flex;
          float: unset;
          width: 120px;
          height: 48px;
          background-color: #3E6617;
          color: #FFFFFF;
          margin: 30px auto;
          font-size: 14px;
        }
      }
    }

    div.bg-leaves {
      margin-top: 80px;
      height: 480px;
    }

    .contact-details-root {
      align-self: center;

      .contact-details-container {
        align-self: center;
        margin-top: 50px;
        padding: 20px 60px 80px;
        display: flex;
        flex-direction: column;

        .title {
          align-self: center;
          font: normal normal bold 30px/41px Oswald;
          margin-bottom: unset;
        }

        .detail {
          padding-top: 20px;

          .bold-text {
            font: normal normal bold 14px/17px Roboto;
          }

          .sub-text {
            color: #707070;
            display: inline-flex;
            font: normal normal normal 14px/17px Roboto;
          }
        }
      }
    }
  }

}