@import "./include-media.scss";

.about-us-root-container {
  padding-top: 140px;
  height: 100vh;
  overflow-y: auto;

  .about-us-container {
    margin-left: 60px;
    padding-right: 70px;

    .see-our-products-link {
      font: normal normal bold 20px/24px Roboto;
      display: flex;
      justify-content: flex-end;
    }

    .about-us-title {
      font-size: 45px;
      font-weight: bold;
      margin-top: 10px;
      font-family: Oswald, sans-serif;
    }

    .content {
      display: flex;
      margin-top: 20px;

      .about-us-content {
        font-size: 22px;
        width: 800px;
        line-height: 29px;
        padding-right: 20px;
        height: 750px;
        overflow-Y: auto;
        font-family: Roboto, sans-serif;
      }

      .mission-vision-container {
        margin-left: 50px;
        width: 480px;
        display: flex;
        flex-direction: column;
        align-self: center;

        span.title {
          font: normal normal bold 24px/29px Roboto;
        }

        div.about-us-content, div.mission-content p, div.vision-content p {
          font: normal normal normal 22px/29px Roboto;
        }
      }
    }
  }

  .about-us-image-container {
    position: absolute;
    bottom: 0;

    .wood-glue-image {
      display: flex;
      position: absolute;
      height: 600px;
      z-index: 2;
      left: 90px;
      bottom: 160px;
      box-shadow: 20px 20px 15px #00000029;
      content: url("/images/wood-glue-2.png");
    }

    .image-bg {
      display: flex;
      position: relative;
      width: 400px;
      height: 600px;
      content: url("/images/close-up-colors-ecology-environment.png");
    }
  }

  .footer-container {
    position: relative;
  }
}

@include media('<=desktop'){
  .about-us-root-container {
    .about-us-image-container {
      position: absolute;
      bottom: 0;

      .wood-glue-image {
        height: 400px;
      }

      .image-bg {
        display: flex;
        position: relative;
        width: 300px;
        height: 400px;
      }
    }
  }
}

@include media('<=phone'){
  .about-us-root-container {
    .page-content { order: 1}
    .page-images {order: 2}
    .footer { order: 3 }

    padding-top: 30px;

    .MuiBreadcrumbs-ol {
      justify-content: center;
    }

    .about-us-container {
      margin: 0 37px;
      padding-right: unset;

      .see-our-products-link {
        font: normal normal bold 14px/17px Roboto;
        justify-content: center;
        margin-bottom: 20px;
      }

      .about-us-title {
        font-size: 28px;
      }

      .content {
        flex-direction: column;
        margin-top: 10px;

        .about-us-content {
          font-size: 14px;
          width: 320px;
          line-height: 20px;
        }

        .mission-vision-container {
          margin-top: 20px;
          margin-left: unset;
          width: 320px;

          span.title {
            text-align: center;
            font: normal normal bold 20px/27px Oswald;
            margin: 20px 0 10px;
          }

          div.about-us-content, div.mission-content p, div.vision-content p {
            font: normal normal normal 14px/20px Roboto;
          }
        }

      }
    }

    .about-us-image-container {
      position: relative;

      .wood-glue-image {
        position: relative;
        margin: 0 auto -240px auto;
        left: unset;
        bottom: unset;
        width: 250px;
        height: 375px;
        content: url("/images/wood-glue-2.png");
      }

      .image-bg {
        content: url("/images/close-up-colors-ecology-environment-mobile.png");
        width: 100%;
        height: auto;
      }
    }
  }
}