@import "include-media.scss";

.product-details-container {
  display: flex;
  flex-direction: column;
  margin: 110px 0 0 200px;
}

.product-details {
  overflow-y: auto;
  margin: 20px 60px 0 0;
  padding-right: 50px;
  color: #000000;
  font: normal normal normal 24px/29px Roboto;
  letter-spacing: 0.24px;
  height: 300px;

  &-no-vid {
    height: 625px;
  }
}

.product-title {
  margin-top: 25px;
  color: #000000;
  font-size: 45px;
  font-family: Oswald, serif;
}

.downloadable-container {
  display: flex;
  flex-direction: column;
  float: right;
  margin: 10px 5px 0 0;
  text-align: right;

  .downloadable-header {
    font-family: RobotoBold, sans-serif;
    font-size: 20px;
  }

  a {
    width: 285px;
  }
}

.image-container {
  box-shadow: 20px 20px 20px #00000029;
  width: 580px;
  height: 680px;
  background-color: #293127;
  border: 8px solid #293127;
  position: absolute;
  margin: 140px 0 20px 40px;
}

.learn-more {
  margin-top: 20px;
  text-decoration: underline;
  font-weight: bold;
  font-size: 30px;
}

.background-nature {
  height: 715px;
  width: 725px;
  object-fit: cover;
  float: right;
  content: url("/images/nature-bg.jpg");
}

.product-demo-title {
  margin-top: 40px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.product-video {
  width: 480px;
  height: 270px;
}

.footer-container {
  position: relative;
}

@include media('<=desktop') {
  .product-details-container {
    margin-left: 150px;
    margin-top: 100px;
  }

  .background-nature {
    height: 500px;
    width: 500px;
  }

  .image-container {
    width: 380px;
    height: 450px;
    margin: 140px 0 20px 40px;
  }

  .learn-more {
    margin-top: 18px;
    font-size: 23px;
  }

  .product-title {
    font-size: 30px;
  }

  .downloadable-container {
    margin: 10px 5px 0 0;
    text-align: right;

    .downloadable-header {
      font-size: 15px;
    }

    a {
      width: 200px;
      font-size: 12px;
    }
  }

  .product-details {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    margin-right: 50px;
    height: 175px;

    &-no-vid {
      height: 450px;
    }
  }

  .product-video {
    width: 400px;
    height: 225px;
  }

  .footer-container {
    margin-top: 60px;
  }
}

@include media('<=phone') {
  .product-details-container {
    align-items: center;
    margin: 20px 0 0 0;
  }

  .product-title {
    margin-top: 10px;
    font: normal normal bold 30px/41px Oswald;
  }

  .product-details {
    overflow-y: auto;
    margin: 20px 40px;
    padding-right: 30px;
    font: normal normal normal 14px/20px Roboto;
    height: unset;
    max-height: 260px;

    &-no-vid {
      height: 300px;
    }
  }

#product-media-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .image-container {
    width: 240px;
    height: 280px;
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .learn-more {
    margin-top: unset;
    align-self: center;
    text-decoration: underline;
    font: normal normal bold 24px/29px Roboto;
  }

  .background-nature {
    position: relative;
    height: 220px;
    width: 100%;
    object-fit: cover;
    margin-top: -255px;
    margin-bottom: 100px;
  }

  #yt-vid-container {
    display: flex;
    flex-direction: column;
    align-self: center;

    .product-demo-title {
      margin-top: unset;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
    }

    .product-video {
      width: 350px;
      height: 230px;
    }
  }

  .downloadable-container {
    display: flex;
    flex-direction: column;
    margin: 50px 20px;
    text-align: unset;

    .downloadable-header {
      font: normal normal bold 20px/24px Roboto;
    }

    a {
      margin: 15px 0 0 10px;
      font: normal normal normal 18px/21px Arial;
      width: unset;
    }
  }

}

  .footer-section {
    order: 5;
    position: relative;
  }
}

