@import "./include-media.scss";

div.footer-container {
  position: relative;
  bottom:0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .footer-text {
    margin-top: 10px;
    margin-bottom: 24px;
    text-align: center;
    font-size: 10px;
    line-height: 20px;
  }
}