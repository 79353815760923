@import "./include-media.scss";

@include media('<=phone') {
  .footer-section {
    padding: 20px 0px;
    flex-direction: column-reverse;
    font-size: 7px;
    line-height: 9px;
    height: auto;

    svg.icon {
      width: 50px;
      height:50px;
    }

    .copyright {
      margin: 5px auto 0;
      white-space: break-spaces;
      text-align: center;
      line-height: 13px;
    }

    .links-and-location {
      display: flex;
      flex-direction: column;
      margin: auto auto 15px;
      text-align: center;

      .icon-container {
        margin: auto;
      }

      .location {
        margin-top: 10px;
      }
    }
  }
}